import transformPatientSearchFormToString from '@/domains/patient/transformers/transformPatientSearchFormToString';
import apiPatients from '@/domains/patient/api/patient.api';
import SortParamsEnum from '@/domains/common/typescript/SortParamsEnum';
import { applyQueryParams } from '@/domains/common/services/utils/buildFilterParams';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useDebounceFn } from '@vueuse/core';
import type PatientInterface from '@/domains/patient/typescript/PatientInterface';
import type PatientSearchFormInterface from '@/domains/patient/typescript/PatientSearchFormInterface';
import type SortByInterface from '@/domains/common/typescript/SortByInterface';

export default defineStore('patient', () => {
    const patients = ref<PatientInterface[]>([]);
    const selectedPatient = ref<PatientInterface>();
    const hasPatientSelected = computed<boolean>(() => selectedPatient.value !== undefined);
    const totalOrders = ref(0);
    const searchForm = ref<PatientSearchFormInterface>({
        name: '',
        lastname: '',
        creationDate: [],
    });
    const sortBy = ref<SortByInterface>({ param: 'creationDate', value: SortParamsEnum.ASC });

    const searchParams = computed<URLSearchParams>(() => {
        return applyQueryParams({
            newParams: { sortBy: sortBy.value, filters: transformPatientSearchFormToString(searchForm.value) },
            currentParams: undefined,
        });
    });

    const fetchPatients = async ({ page, limit } = { page: 1, limit: 10 }) => {
        searchParams.value.append('page', page.toString());
        searchParams.value.append('itemsPerPage', limit.toString());

        const { entities, total } = await apiPatients.getPatients(searchParams.value);

        patients.value = entities;
        totalOrders.value = total;
    };

    const debouncedFetchPatients = useDebounceFn(fetchPatients, 600);

    watch(searchParams, () => {
        debouncedFetchPatients();
    });

    const selectPatientById = (patientId: string): void => {
        selectedPatient.value = patients.value.find((patient) => patient.id === patientId);
    };

    const isPatientSelected = (patient: PatientInterface): boolean => selectedPatient.value?.id === patient.id;

    const unselectPatient = (): void => {
        selectedPatient.value = undefined;
    };

    function $reset() {
        console.info('reset patient store');
        patients.value = [];
        selectedPatient.value = undefined;
    }

    return {
        patients,
        selectedPatient,
        hasPatientSelected,
        totalOrders,
        sortBy,
        searchForm,
        fetchPatients,
        selectPatientById,
        isPatientSelected,
        unselectPatient,

        $reset,
    };
});
