import api from '@/domains/scan/api/scan.api';
import ScanFileTypeValue from '@/domains/scan/typescript/enums/ScanFileTypeValue';
import getFileExtension from '@/domains/common/services/utils/getFileExtension';
import timer from '@/domains/common/services/utils/timer';
import eventBus from '@/domains/common/services/eventBus';
import type MessageInterface from '@/domains/scan/typescript/interfaces/MessageInterface';
import type ScanInterface from '@/domains/scan/typescript/interfaces/ScanInterface';
import type ScanFilesBlobInterface from '@/domains/scan/typescript/interfaces/ScanFilesBlobInterface';
import type ScanStatusInterface from '@/domains/scan/typescript/interfaces/ScanStatusInterface';
import type ScanStatusType from '@/domains/scan/typescript/types/ScanStatusType';
import type DeviceInterface from '@/domains/scan/typescript/interfaces/DeviceInterface';

const MAX_TRY_SCAN_STATUS_API_CALL = 10;

const waitScanAvailability = async (scanId: string): Promise<void> => {
    let scanStatusResponse: ScanStatusInterface | MessageInterface = await api.getScanStatus(scanId);

    if (!('scanId' in scanStatusResponse)) {
        throw Error(scanStatusResponse.message);
    }

    let canPollScan = true;
    let scanStatus: ScanStatusType = (scanStatusResponse as ScanStatusInterface).status;
    let tryAmount = 1;

    eventBus.on('stop-polling-scan', () => {
        canPollScan = false;
    });

    while (scanStatus !== 'success' || tryAmount < MAX_TRY_SCAN_STATUS_API_CALL) {
        if (!canPollScan) {
            break;
        }
        /* eslint-disable no-await-in-loop */
        await timer(1000); // wait 1 second until next status request

        /* eslint-disable no-await-in-loop */
        scanStatusResponse = await api.getScanStatus(scanId);
        tryAmount++;

        if ('scanId' in scanStatusResponse) {
            scanStatus = scanStatusResponse.status;
        }
    }

    if (scanStatus !== 'success') {
        throw Error(`Scan is not available for the moment, status: (${scanStatus})`);
    }
};

const getScanFiles = async (scanId: string): Promise<ScanFilesBlobInterface> => {
    const scanFiles: Array<string> | MessageInterface = await api.getScanFiles(scanId);
    if ('message' in scanFiles) {
        throw Error(scanFiles.message);
    }

    let promise = new Promise<ScanFilesBlobInterface>((resolve) =>
        resolve({ jpg: new Blob(), mtl: new Blob(), obj: new Blob() }),
    );
    scanFiles.forEach((scanFileName) => {
        promise = promise.then(async (files: ScanFilesBlobInterface): Promise<ScanFilesBlobInterface> => {
            const file = await api.getScanFile({ scanId, fileId: scanFileName });

            if ('message' in file) {
                throw Error(file.message);
            }

            const extension = getFileExtension(scanFileName);
            if (extension in ScanFileTypeValue) {
                files[extension as ScanFileTypeValue] = file;
            }

            return files;
        });
    });

    return promise.then((files) => files);
};

const scan = async (
    device: DeviceInterface,
    { brightness, contrast, lightSensibility }: { brightness?: number; contrast?: number; lightSensibility?: string },
): Promise<ScanFilesBlobInterface | undefined> => {
    const scanOptions = {
        backend: device.backend,
        brightness,
        contrast,
        lightSensibility,
    };

    const newScan: ScanInterface | MessageInterface = await api.askNewScan(device.deviceId, scanOptions);
    if ('message' in newScan) {
        throw Error(newScan.message);
    }

    const { scanId } = newScan as ScanInterface;

    await waitScanAvailability(scanId);

    return getScanFiles(scanId);
};

export default { scan };
